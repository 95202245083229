import React from 'react';
import { useState, useEffect } from 'react';

function useIsMobile() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const updateIsMobile = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust breakpoint as needed
    };

    updateIsMobile();
    window.addEventListener('resize', updateIsMobile);
    return () => window.removeEventListener('resize', updateIsMobile);
  }, []);

  return isMobile;
}

function ResponsiveImage({ src, alt, className, maxWidth = '100%', maxHeight = 'auto' }) {
  const isMobile = useIsMobile();
  const [baseUrl, imagePath] = src.split('/upload/');
  const transformations = 'f_auto,q_auto,w_auto';

  return (
    <div className={`flex justify-center max-w-full h-auto ${className}`}>
      <img
        src={`${baseUrl}/upload/${transformations}/${imagePath}`}
        alt={alt}
        className="w-full h-auto rounded-lg shadow-lg"
        style={{ maxWidth: isMobile ? '100%' : maxWidth, maxHeight }}
        loading="lazy"
      />
    </div>
  );
}

export default ResponsiveImage;