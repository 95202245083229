import React from 'react';
import { useNavigate } from 'react-router-dom';

function Footer() {
  const navigate = useNavigate();

  const handleScrollToTop = (path) => {
    navigate(path);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <footer className="bg-primary text-background py-8">
      <div className="container mx-auto px-[8%]">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="mb-4 md:mb-0 text-xs">
            &copy; {new Date().getFullYear()} Biokläranlagen Gilge. Alle Rechte vorbehalten.
          </div>
          <div className="flex space-x-4">
            <button onClick={() => handleScrollToTop('/impressum')} className="hover:underline">
              Impressum
            </button>
            <button onClick={() => handleScrollToTop('/datenschutz')} className="hover:underline">
              Datenschutz
            </button>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;