import React from 'react';
import SectionWrapper from './SectionWrapper';

function ContactInfo() {
  return (
    <SectionWrapper className='bg-secondary'>
      <section className="text-background py-12 w-full" id="about">
        <div className="max-w-6xl mx-auto px-4">
          <h2 className="text-3xl font-bold mb-12 text-center">Kontaktieren Sie uns</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 text-center justify-items-center">
            {/* <div>
              <h3 className="text-xl font-bold mb-4">Adresse</h3>
              <p>Biokläranlagen Gilge<br />Oberhof 1a<br />18190 Sanitz</p>
            </div> */}
            <div>
              <h3 className="text-xl font-bold mb-4">Telefon</h3>
              <p>
                Mobil: <a href="tel:+491607951783" className="hover:underline">0160 7951 783</a><br />
                Tel: <a href="tel:+4938209499923" className="hover:underline">038209 49 99 23</a><br />
                Fax: 038209 87 52 91
              </p>
            </div>
            <div>
              <h3 className="text-xl font-bold mb-4">E-Mail</h3>
              <p>
                <a href="mailto:info@bioklaeranlagen-gilge.de" className="hover:underline">
                  info@bioklaeranlagen-gilge.de
                </a>
              </p>
            </div>
            <div>
              <h3 className="text-xl font-bold mb-4">Öffnungszeiten</h3>
              <p>Montag - Freitag<br />07:00 Uhr bis 17:00 Uhr</p>
            </div>
          </div>
        </div>
      </section>
    </SectionWrapper>
  );
}

export default ContactInfo;