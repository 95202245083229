// pages/Home.js
import React from 'react';
import Hero from '../components/Hero';
import ContactInfo from '../components/ContactInfo';
import About from '../components/About';
import Services from '../components/Services';
import Testimonials from '../components/Testimonials';



function Home() {
  return (
    <>
      <Hero />
        <ContactInfo />
        <About />
        <Services />
        <Testimonials />
    </>
  );
}

export default Home;