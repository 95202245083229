import React from 'react';
import SectionWrapper from '../components/SectionWrapper';

function PageNotFound() {
  return (
    <section>
      <SectionWrapper>
        <div className="mt-5 container mx-auto px-4 py-16 bg-white shadow-md rounded-lg text-center">
          <h1 className="text-3xl font-bold text-primary mb-8 border-b pb-4">404 - Seite nicht gefunden</h1>
          <div className="prose max-w-none space-y-6">
            <p>
              Die von Ihnen gesuchte Seite konnte nicht gefunden werden. Bitte überprüfen Sie die URL oder kehren Sie zur Startseite zurück.
            </p>
            <p>
              <a href="/" className="text-primary hover:underline">Zurück zur Startseite</a>
            </p>
          </div>
        </div>
      </SectionWrapper>
    </section>
  );
}

export default PageNotFound;