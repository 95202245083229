import React from 'react';
import styled from 'styled-components';

const StyledWrapper = styled.div`
  width: 95%;
  max-width: 1824px; // 95% von 1920px
  margin-left: auto;
  margin-right: auto;
  padding-top: 2rem;
  padding-bottom: 2rem;

  @media (min-width: 1920px) {
    width: 90%; // Erhöht den Abstand auf größeren Bildschirmen
  }
`;

function SectionWrapper({ children, className = '', wrapperClassName = '' }) {
  return (
    <div className={wrapperClassName}>
      <StyledWrapper className={className}>
        {children}
      </StyledWrapper>
    </div>
  );
}

export default SectionWrapper;