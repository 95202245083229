import React from 'react';
import SectionWrapper from './SectionWrapper';

function Testimonials() {
  const testimonials = [
    {
      text: "Biokläranlagen Gilge hat unsere Anlage schnell und professionell installiert. Wir sind sehr zufrieden mit dem Service!",
      author: "Walter Mutter"
    },
    {
      text: "Die jährliche Wartung wird immer zuverlässig durchgeführt. Ein kompetentes Team, das ich nur empfehlen kann.",
      author: "Dagmar Horst"
    },
    {
      text: "Hervorragende Beratung und top Kundenservice. Danke für die gute Zusammenarbeit!",
      author: "Familie Lühring"
    }

  ];

  return (
    <section className="py-16">
      <SectionWrapper className='bg-background'>
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-primary mb-12 text-center">Das sagen unsere Kunden</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {testimonials.map((testimonial, index) => (
              <div key={index} className="bg-white rounded-lg shadow-md p-6 relative h-full">
                <p className="text-gray-700 italic mb-4">"{testimonial.text}"</p>
                <p className="absolute bottom-4 right-4 font-bold text-secondary">- {testimonial.author}</p>
              </div>
            ))}
          </div>
        </div>
      </SectionWrapper>
    </section>
  );
}

export default Testimonials;