import React, { useState, useRef, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const modalRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      setIsScrolled(offset > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToSection = (id) => {
    const isContactSection = id === 'contact';

    // Wenn die ID 'contact' ist, navigiere direkt zur Kontaktform
    if (isContactSection) {
      const section = document.getElementById(id);
      if (section) {
        const offset = 64; // Höhe der Navbar oder gewünschter Offset in Pixel
        const elementPosition = section.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.scrollY - offset;

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth',
        });
      }
    } else {
      // Navigiere zur Startseite für andere IDs
      if (location.pathname !== '/') {
        navigate('/');
      }
      // Delay hinzufügen, um sicherzustellen, dass die Navigation stattgefunden hat
      setTimeout(() => {
        const section = document.getElementById(id);
        if (section) {
          const offset = 64; // Höhe der Navbar oder gewünschter Offset in Pixel
          const elementPosition = section.getBoundingClientRect().top;
          const offsetPosition = elementPosition + window.scrollY - offset;

          window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth',
          });
        }
      }, 100); // Delay to ensure navigation has occurred
    }

    setIsMenuOpen(false);
  };

  const navigateAndScrollTop = (path) => {
    navigate(path);
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setIsMenuOpen(false);
  };

  // Check if the current path is Impressum, Datenschutz, or Projekte
  const isSpecialPage = location.pathname !== '/';

  return (
    <>
      <nav className={`text-background p-4 fixed top-0 left-0 right-0 z-50 transition-all duration-300 ${isScrolled || isMenuOpen || isSpecialPage ? 'bg-primary' : 'bg-transparent'}`}>
        <div className="container mx-auto flex justify-between items-center max-w-[1920px] px-[8%]">
          <div className="relative">
            <div
              className="absolute inset-0 bg-primary z-5"
              style={{
                height: '100%',
                width: '100%',
                transform: 'translateY(-100%)',
              }}
            ></div>
            <div
              className="absolute inset-0 bg-primary z-5"
              style={{
                height: '100%',
                width: '100%',
                transform: 'translateY(20%)',
                borderBottomLeftRadius: '0.75rem',
                borderBottomRightRadius: '0.75rem',
              }}
            ></div>
            <Link to="/" className="text-xl font-bold relative z-10">
              <img
                src="https://res.cloudinary.com/dcjd8htwp/image/upload/v1727654902/BioklaeranlagenGilge/1727635256293_xuuwkd.jpg"
                alt="Biokläranlagen Gilge"
                className="h-10 w-auto object-contain"
              />
            </Link>
          </div>

          <button
            className={`lg:hidden w-6 h-6 flex flex-col justify-center items-center burger-button ${isMenuOpen ? 'open' : ''}`}
            onClick={toggleMenu}
            aria-label="Toggle menu"
          >
            <span className="burger-line"></span>
            <span className="burger-line"></span>
            <span className="burger-line"></span>
          </button>

          <ul className="hidden lg:flex space-x-8 text-lg">
            <li><button onClick={() => scrollToSection('home')} className="hover:underline">Home</button></li>
            <li><button onClick={() => scrollToSection('about')} className="hover:underline">Über uns</button></li>
            <li><button onClick={() => scrollToSection('services')} className="hover:underline">Leistungen</button></li>
            <li><button onClick={() => scrollToSection('contact')} className="hover:underline">Kontakt</button></li>
            <li><button onClick={() => navigateAndScrollTop('/galerie')} className="hover:underline">Galerie</button></li>
          </ul>
        </div>
      </nav>

      {/* Modal */}
      <CSSTransition
        in={isMenuOpen}
        timeout={300}
        classNames="menu"
        unmountOnExit
        nodeRef={modalRef}
      >
        <>
          <div
            className="fixed inset-0 bg-black bg-opacity-60 z-30"
            onClick={() => setIsMenuOpen(false)}
          ></div>
          <div ref={modalRef} className="fixed inset-x-0 top-[64px] bg-primary z-40 flex flex-col items-start justify-start h-[50vh] px-4 py-8">
            <ul className="space-y-8 text-left text-[#fcfcfc] text-lg">
              <li><button onClick={() => scrollToSection('home')}>Home</button></li>
              <li><button onClick={() => scrollToSection('about')}>Über uns</button></li>
              <li><button onClick={() => scrollToSection('services')}>Leistungen</button></li>
              <li><button onClick={() => scrollToSection('contact')}>Kontakt</button></li>
              <li><button onClick={() => navigateAndScrollTop('/galerie')}>Galerie</button></li>
            </ul>
          </div>
        </>
      </CSSTransition>
    </>
  );
}

export default Navbar;
